import {
  Box,
  Flex,
  FlexProps,
  Heading,
  Icon,
  Link,
  Text,
} from "@chakra-ui/react"
import * as React from "react"
import { PageProps } from "gatsby"
import { FaClock, FaPhone } from "react-icons/fa"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import ScheduleVisitForm from "../components/stagedScheduleVisitForm"
import { Hours } from "../data/hours"
import BlockHeader from "../components/blockHeader"
import { getWeeklySummary } from "../utils/timeUtils"
import SEO from "../components/CoreUI/SEO"

function UniformFlex(props: FlexProps): JSX.Element {
  return (
    <Flex
      align="center"
      flexDir={{ base: `column`, md: `row` }}
      flexWrap="wrap"
      justify="center"
      maxW="95vw"
      mx="auto"
      px={4}
      py={1}
      w="800px"
      {...props}
    />
  )
}

function ScheduleConsultation({ location }: PageProps): JSX.Element {
  return (
    <Layout>
      <SEO
        meta={{
          description:
            "Whether you’re building a home for the first time or the fifth, we’re happy to sit down and figure it all out with you.",
          image: `${location.origin}/sundog-design-studio-logo-wall-1.jpg`,
          imageAlt: `The interior of the Sundog Homes design studio`,
        }}
        title="Schedule Your Free Consultation | Sundog Homes"
        url={location.href}
      />
      <UniformFlex flexDir="column" mb={12}>
        <StaticImage
          alt="Brannon, Phillip, and David of Sundog Homes."
          src="../images/sundog-design-studio-team-1.jpg"
          width={800}
          style={{
            borderRadius: `var(--chakra-radii-lg)`,
            boxShadow: `var(--chakra-shadows-lg)`,
            maxWidth: `95vw`,
          }}
        />
        <BlockHeader mt={8}>Schedule Your Free Consultation</BlockHeader>
        <Text fontFamily="heading" fontSize="5xl" fontWeight="bold" mt={2}>
          Let’s Talk
        </Text>
        <Text fontSize="xl" mt={2}>
          We don’t believe in hard sells or sales pitches — we believe in
          conversations. Our free, no-obligation consultations are an
          opportunity for us to learn about you and your project, and for you to
          learn about us and how we operate. Schedule yours today.
        </Text>
      </UniformFlex>
      <UniformFlex align="flex-start" justify="space-between" mb={12}>
        <UniformFlex
          border="1px solid"
          borderColor="gray.100"
          rounded="lg"
          px={{ md: 0 }}
          ml={{ md: 0 }}
          py={8}
          shadow="sm"
          w={{ base: `100%`, md: `400px` }}
        >
          <Box px={[4, 4, 6]} maxW="400px" w="100%">
            <ScheduleVisitForm buttonColor="teal" context={[]} />
          </Box>
        </UniformFlex>
        <UniformFlex
          flexDir={{ base: `column`, sm: `row`, md: `column` }}
          justify="space-between"
          mr={{ md: 0 }}
          py={{ base: 12, md: 0 }}
          px={{ md: 0 }}
          w={{ base: `100%`, md: `300px` }}
        >
          <UniformFlex
            bg="red.400"
            border="2px solid"
            borderColor="red.500"
            color="white"
            flexDir="column"
            py={8}
            rounded="lg"
            shadow="md"
            w={{ base: `100%`, sm: `48%`, md: `300px` }}
          >
            <Icon as={FaPhone} color="red.800" fontSize="36px" />
            <Text fontSize="2xl" fontWeight="bold" mt={2}>
              Schedule by Phone
            </Text>
            <Link fontSize="xl" href="tel:8287745720">
              (828) 774-5720
            </Link>
          </UniformFlex>
          <UniformFlex
            bg="teal.400"
            border="2px solid"
            borderColor="teal.500"
            color="white"
            flexDir="column"
            mt={6}
            py={8}
            rounded="lg"
            shadow="md"
            w={{ base: `100%`, sm: `48%`, md: `300px` }}
          >
            <Icon as={FaClock} color="teal.800" fontSize="36px" />
            <Text fontSize="2xl" fontWeight="bold" mt={2}>
              Our Hours
            </Text>
            {getWeeklySummary(Hours, `%dd1 - %dd2, `).map((line, index) => (
              <Flex
                fontSize="xl"
                justify="space-between"
                key={index}
                maxW="95%"
                mx={{ base: `auto`, md: 0 }}
                w="220px"
              >
                <Box mr={{ base: 0, md: 1 }}>{line[0]}</Box>
                <Box>{line[1]}</Box>
              </Flex>
            ))}
          </UniformFlex>
        </UniformFlex>
      </UniformFlex>
    </Layout>
  )
}

export default ScheduleConsultation
